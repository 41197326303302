import React from "react"

import Seo from "../components/Seo"

const NotFoundPage = () => {
  return (
    <>
      <Seo title="Not Found" />
      <div>Not Found</div>
    </>
  )
}

export default NotFoundPage
